<template>
  <div>
    <b-card
      v-if="devicetypeData"
    >
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="7"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ devicetypeData.name }}
                </h4>
              </div>
              <div class="d-flex flex-wrap">
                <b-button
                  :to="{ name: 'device-types-update', params: { id: devicetypeData.id } }"
                  variant="primary"
                >
                  Modifier
                </b-button>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="5"
        >
          <div class="mt-2 mt-xl-0 w-100">
            {{ devicetypeData.comment }}
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      devicetypeData: null,
    }
  },
  mounted() {
    this.getDevicetype()
  },
  methods: {
    getDevicetype() {
      store.dispatch('devicetype/findDevicetype', this.$router.currentRoute.params.id)
        .then(response => {
          this.devicetypeData = response
        })
    },
  },
}
</script>
